import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"

const SecondPage = () => (
	<Layout>
		<SEO
			title="Online Training"
			description="The NARM Training Institute Presents The NARM INNER CIRCLE Online Membership Program "
		/>
		<div class="page-headline">
			<div class="container">
				<div class="section-heading text-center">
					<h5 class="font-weight-bold text-uppercase text-orange flair mb-0">
						Online Training
					</h5>
					<h1>
						The NARM Training Institute Presents The NARM INNER CIRCLE Online
						Membership Program
					</h1>
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div class="row justify-content-between align-items-center">
					<iframe
						src="https://narm.kartra.com/page/InnerCircle"
						style={{ height: "5000px", width: "100%", border: "0px" }}
					></iframe>
				</div>
			</div>
		</div>
	</Layout>
)

export default SecondPage
